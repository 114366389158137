import logo from './logo-red.svg';
import { SocialIcon } from 'react-social-icons';
import './App.css';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-SJ1VBR5KW8";
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <section>
          <div className="Header"> 
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="Header-text">3 Joy Studio</h1>
          </div>
          <div className="Paragraph">
              <ul className="List">
                <li className="List-item">We are a creative studio<br /></li>
                <li className="List-item">Tech is our canvas<br /></li>
                <li className="List-item">Our studio focuses on Web3, but creativity knows no boundaries<br /></li>
                <li className="List-item">We bring joy to our customers, partners, and the whole community</li>
              </ul>
            <footer className="Footer">Get in touch at &nbsp;
              <a
                className="App-link"
                href="mailto: hello@3joy.studio"
              >
                hello@3joy.studio
              </a>
              &nbsp; and &nbsp;
              <SocialIcon network="twitter" url="https://twitter.com/3joyStudio" bgColor="#F00C18" fgColor="#ffffff" style={{ height: 50, width: 50 }}/>
            </footer>
          </div>
        </section>
      </header>
    </div>
  );
}

export default App;
